const GRID_SIZE = 4;

export const dims = {
    spacings: {
        one: '1px',
        tiny: `${GRID_SIZE}px`,
        small: `${2 * GRID_SIZE}px`,
        large: `${3 * GRID_SIZE}px`,
        default: `${4 * GRID_SIZE}px`,
        larger: `${5 * GRID_SIZE}px`,
        largest: `${6 * GRID_SIZE}px`,
        huge: `${8 * GRID_SIZE}px`,
        colossal: `${10 * GRID_SIZE}px`,
        grand: `${12 * GRID_SIZE}px`,
        magnificent: `${16 * GRID_SIZE}px`,
        giant: `${19 * GRID_SIZE}px`
    },
    radiuses: {
        tiny: '4px',
        smaller: '5px',
        small: '6px',
        medium: '8px',
        big: '10px',
        large: '16px',
        larger: '18px',
        largest: '20px',
        huge: '40px'
    },
    scrollbar: {
        width: '0.438rem',
        paddingRight: '0.063rem',
        track: {
            width: '0.625rem',
            right: '0rem'
        }
    },
    spinnerSize: '100px'
};
