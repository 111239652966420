import React from 'react';
import PropTypes from 'prop-types';
import { ItemWrapper } from './ItemWrapper';
import { ItemHeader } from './ItemHeader';
import { ItemThumbnail } from './ItemThumbnail';
import { ItemThumbnailWrapper } from './ItemThumbnailWrapper';

export const Item = ({
    name,
    thumbnailUrl,
    onClick,
    wrapperSize
}) => (
    <ItemWrapper
        numberOfItems={3}
        onClick={onClick}
        wrapperSize={wrapperSize}
    >
        <ItemHeader>
            {name}
        </ItemHeader>
        <ItemThumbnailWrapper>
            <ItemThumbnail src={thumbnailUrl} />
        </ItemThumbnailWrapper>
    </ItemWrapper>
);

Item.propTypes = {
    name: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    wrapperSize: PropTypes.any.isRequired
};

Item.defaultProps = {
};
