import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { identity } from 'ramda';

import { FlexRowWrapper } from '../../../../components/layout/FlexRowWrapper';
import { Item } from './Item';

export const TemplateListItem = ({
    listItems,
    onClick
}) => (
    <FlexRowWrapper
        wrap="wrap"
        fullHeight
        fullWidth
        overflowY="scroll"
    >
        {listItems.map((listItem) => (
            <Item
                wrapperSize={800}
                key={`${listItem.get('name')}`}
                name={listItem.get('name')}
                thumbnailUrl={listItem.get('thumbnailUrl')}
                onClick={() => onClick(listItem.get('filename'))}
            />
        )
        ).toJS()}
    </FlexRowWrapper>
);

/*eslint-disable */
TemplateListItem.propTypes = {
    listItems: PropTypes.instanceOf(List),
    onClick: PropTypes.func,
    width: PropTypes.number,
    outerHeight: PropTypes.number
};

TemplateListItem.defaultProps = {
    listItems: List(),
    onClick: identity,
    width: null,
    height: null
};
