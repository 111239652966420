import React, { useEffect, useState } from 'react';
import { fromJS, List } from 'immutable';

import { TemplateListItem } from './components/ListItem';
import { api } from '../../api/config';
import { CANVAS_MODEL_IDS } from '../../canvas/canvas-definitions/consts';
import { getTemplateThumbnailFilePath } from './helpers';

export const NewCanvasModal = () => {
    const openDemoCanvas = (id) => {
        window.location.href = `http://next.canvanizer.com/demo/${id}`;
    };

    const [templates, setTemplates] = useState(List());

    useEffect(() => {
        api.get('https://backend.canvanizer.com/api/v1/canvases/categories')
            .then(({ data: categories }) => {
                const canvasIdSet = {}; // dedup canvases for canvases list since they can occur multiple times
                const canvases = [];

                categories.forEach((category) => {
                    category.canvasTypes.forEach((canvasInfo) => {
                        if (!canvasIdSet[canvasInfo.id]) {
                            const filename = CANVAS_MODEL_IDS[canvasInfo.id];
                            // console.log(canvasInfo);
                            canvases.push({
                                filename,
                                thumbnailUrl: getTemplateThumbnailFilePath(filename),
                                ...canvasInfo
                            });
                            canvasIdSet[canvasInfo.id] = true;
                        }
                    });
                });

                setTemplates(fromJS(canvases));
            });
    }, []);

    const filteredTemplates = templates.filter(() => true);

    const sortedTemplates = filteredTemplates.sortBy((template) => template.get('name'));

    return (
        <TemplateListItem
            listItems={sortedTemplates}
            onClick={openDemoCanvas}
        />
    );
};
