import { fontFamilies, fontWeights, typeScale } from './typography';

export const fonts = {
    header1: `
        font-family: ${fontFamilies.light};
        font-size: ${typeScale.huge};
        font-weight: ${fontWeights.lighter};
    `,
    header2: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.larger};
        font-weight: ${fontWeights.lighter};
    `,
    header2Light: `
        font-family: ${fontFamilies.light};
        font-size: ${typeScale.larger};
        font-weight: ${fontWeights.lighter};
    `,
    header3: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.large};
        font-weight: ${fontWeights.normal};
    `,
    header4: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.normal};
    `,
    header5: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.small};
        font-weight: ${fontWeights.bold};
    `,
    header6: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.smaller};
        font-weight: ${fontWeights.bold};
    `,
    userMenuNewsParagraph: `
        font-family: ${fontFamilies.light};
        font-size: ${typeScale.small};
        font-weight: ${fontWeights.normal};
    `,
    annotation: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.microscopic};
        font-weight: ${fontWeights.normal};
    `,
    label: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.tiny};
        font-weight: ${fontWeights.normal};
    `,
    canvasTitleSmall: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.bold};
        text-align: center;
    `,
    userLabel: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.smaller};
        font-weight: ${fontWeights.normal};
    `,
    newCanvasModalDetailsTitle: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.larger};
        font-weight: ${fontWeights.normal};
    `,
    newCanvasModalDetailsDescription: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.smallest};
        font-weight: ${fontWeights.normal};
    `,
    newCanvasModalDetailsCredits: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.microscopic};
        font-weight: ${fontWeights.normal};
    `,
    upgradeModalBold: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.bold};
    `
};
