import { ThemeProvider } from 'styled-components';
import { NewCanvasModal } from './app/containers/NewCanvasModal';
import './assets/fonts/fonts.css';
import { theme } from './app/themes';

const App = () => (
    <div className="App">
        <ThemeProvider theme={theme}>
            <NewCanvasModal />
        </ThemeProvider>
    </div>
);

export default App;
